<template>
	<div class="Shop">
		
		<div class="Top">
			<div class="Left">
				<el-button type="primary" @click="$Jump('/do/shop/shop_info/add')">添加店铺分类</el-button>
			</div>
			<div class="Right">
				<span>
					<el-select placeholder="请选择查询条件" v-model="SelectType">
					    <el-option label="按店铺分类ID号查询" value="Id"></el-option>
						 <el-option label="按店铺分类名查询" value="Name"></el-option>
					  </el-select>
				</span>
				<span style="margin-left: 10px;margin-right: 10px;">
					<el-input placeholder="请输入查询内容" v-model="SearchKey"></el-input>
				</span>
				<span>
					<el-button type="primary" @click="GetList(Page)">搜索</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="Title">
				<div class="C1">店铺分类名</div>
				<div class="C3">状态</div>
				<div class="C4">创建时间</div>
				<div class="C5">分类ID</div>
				<div class="C6">操作</div>
			</div>
			
			<div class="One" v-for="item in ShopList" :key="item.Id">
				<li>
					<div class="C1">{{item.Name}}</div>
					<div class="C3">{{item.StatusName}}</div>
					<div class="C4">{{item.CreatedAt}}</div>
					<div class="C5">{{item.Id}}</div>
					<div class="C6">
						<el-button size="mini" @click="$Jump('/do/shop/shop_info/'+item.Id)">查看&管理</el-button>
					</div>
				</li>
			</div>
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'ShopCategoryList',
	  props: {
	  },
	  data() {
	      return {
			  SelectType:'',
			  SearchKey:'',
			  ShopList:[],
			  Page:1,
			  PageSize:15,
			  Total:0
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
	  	this.GetList(1)
	  },
	  methods:{
		  handleCurrentChange(val){
			 this.GetList(val)
		  },
		  GetList(_page){
		  		let that = this
		  		let data = {
		  			Service:'Shop',
		  			Class: 'Shop',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:that.PageSize,
					SelectType:that.SelectType,
					SearchKey:that.SearchKey,
		  		}
		  		this.$post(that.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				that.$message(res.Msg)
		  				return
		  			}
					that.ShopList = res.Data.ShopList
					that.Page = res.Data.CurrentPage
					that.Total = res.Data.Total
		  			
		  		})
		  		.catch(function (response) {
		  			that.DialogMsg = '网络请求错误'
		  		})
		  }
	  }
	}
</script>

<style scoped>
.Shop{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Shop .Top{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding-bottom: 20px;
}
.Shop .Top .Left{
	
}
.Shop .Top .Right{
	flex: 1;
	display: flex;
	color: #999999;
	justify-content: flex-end;
}
.Shop .Top .Right span{
	margin-left: 10px;
}
.Shop .List{
}
.C1,.C2,.C3,.C4,.C5{
	width: 150px;
}
.C1{
	padding-left: 10px;
}
.C2 img{
	height: 25px;
}
.C5{
	flex: 1;
	color: rgba(0,0,0,0.25);
}
.C6{
	padding-right: 10px;
	text-align: right;
}
.Shop .List .Title{
	display: flex;
	margin-bottom: 20px;
	color: rgba(0,0,0,0.3);
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	font-size: 0.9rem;
}
.Shop .List .One{
	border-bottom: 1px solid rgba(0,0,0,0.04);
	padding: 10px 0px;
}
.Shop .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.Shop .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
</style>
